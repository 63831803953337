import { render, staticRenderFns } from "./Ic5gPcfView.vue?vue&type=template&id=5d33eed6&scoped=true"
import script from "./Ic5gPcfView.vue?vue&type=script&setup=true&lang=js"
export * from "./Ic5gPcfView.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d33eed6",
  null
  
)

export default component.exports